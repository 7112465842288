.container {
    width: 100vw;
    height: 100vh;
    background-color: #8f93b6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imageContainer {
    width: 97vw;
    max-width: 700px;
}

.title {
    color: #e2dee8;
    font-size: 2em;
    letter-spacing: 3px;
    text-align: center;
}