.container {
    margin-top: 16px;
    margin-bottom: 16px;

    :global {
        .ant-btn-default {

            display: flex;
            align-items: center;
            padding: 4px 10px;
            border-radius: 5px;
            height: 38px;
            background-color: #1890ff;
            border: 1px solid #1890ff;
            color: #FFFFFF;
            font-size: 15px;

            &:hover,
            &:active,
            &:focus {
                background-color: #3758b6;
                border-color: #3758b6;
            }
        }
    }
}

.container .cards {
    min-height: 100vh;
}

.container .pagination {
    text-align: center;
}

.container .header {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    justify-content: end;
}

.container .noAnnouncements {
    color: #808285;
    font-weight: normal;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.deleteModalContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconDeleteModal {
    margin-right: 5px;
    color: #faad14;
}

.titleDeleteModal {
    font-size: 16px;
    font-weight: 500;
}

.radioIcon {
    :global {
        .ant-radio-inner {
            width: 18px;
            height: 18px;
        }
    }
}

.deleteButton {
    margin-right: auto;
}