.submitButton {
    padding: 5px 10px !important;
    background-color: #6786b8 !important;
    border: 1px solid #6786b8 !important;
    color: #FFFFFF !important;
    cursor: pointer;
    border-radius: 5px !important;
    transition: all .2s ease-in;

    &:hover,
    &:active,
    &:focus {
        background-color: #3a547f !important;
        border-color: #3a547f !important;
        color: #FFFFFF !important;
    }

    &:disabled {
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: #d9d9d9 !important;
        background: #ebebeb !important;
    }
}