@import "../../../styles/globals.scss";

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 0 5px 5px;
    font-size: 14px;
    padding: 5px 0;
    overflow-x: hidden;
}

.dateContainer {
    padding-left: 30px;
    padding-right: 5px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
    color:rgba(0,0,0,.85);
    .searchIcon{
        align-items: left;
    }
}

.header {
    margin-top: 10px;
    display: flex;
    align-items: center;
    height: $JOURNAL_MOBILE_HEADER_HEIGHT + $DATE_BUTTON_STATUS_DATE_HEIGHT;
}

.filterSelector {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;

    :global {
        .ant-select-clear {
            top: 45%;
            right: 15px;
        }

        .ant-select-selector {
            min-height: 40px;
            border-radius: 5px !important;
            padding: 5px 15px !important;
            font-size: 16px;
        }

        .ant-select-arrow {
            padding-top: 8px;
        }
    }
}

.currentLesson {
    outline: 3px solid #95c1cf;
    border-radius: 10px;
    animation: border-pulsate 2s infinite !important;
}

@keyframes border-pulsate {
    0% { outline-color: rgba(0, 255, 255, 1); }
    50%  { outline-color: rgba(0, 255, 255, 0); }
    100% { outline-color: rgba(0, 255, 255, 1); }
}

.noLessons {
    color: #808285;
    font-weight: normal;
    margin-bottom: 0 !important;
    font-size: 16px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.info {
    font-size: 11px;
    color: #808285;
    margin-top: 12px;   
    margin-left: 2px; 
}

.skeleton {
    padding-top: 10px;
    padding-left: 10px;
}

span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple_anim 250ms linear;
    background-color: rgba(177, 169, 169, 0.7);
}

@keyframes ripple_anim {
    100%
    {
        transform: scale(2);
        opacity: 0;
    }
}