@import '../../../styles/animations.scss';

.contactsForm {
    &>div {
        margin: 0 0 15px;
    }

    input {
        &:disabled {
            color: gray !important;
        }
    }

    :global {
        .ant-form-item-explain-error {
            font-size: 13px;
        }
    }
}

.contactTitle {
    font-size: 18px;
    font-weight: bold;
}

.contactStatus {
    margin: 10px 0;

    &>span {
        padding: 3px 7px;
        border-radius: 5px;
        width: 100%;
        max-width: 200px;
        text-align: center;
        height: 32px;
        text-overflow: ellipsis;
        overflow: hidden;

        svg {
            vertical-align: bottom;
            margin-right: 5px;
        }

        span {
            font-size: 14px;
        }
    }
}

.animateAppearance {
    animation: slide-up .5s ease;
}

.verificationContainer {
    animation: slide-down-appear .5s ease;

    .codeContainer,
    .codeControlsContainer {
        display: flex;
        align-items: center;
        column-gap: 20px;
        position: relative;
    }

    .codeControlsContainer {
        margin-top: 25px;

        button {
            width: 50%;
        }
    }

    .codeInput,
    .codeInputInvalid {
        input[type=number] {
            -moz-appearance: textfield;
        }

        input {
            box-shadow: none;
            font-family: monospace;
            border-radius: 5px;
            border: 1px solid lightgrey;
            margin-right: 5px;
            font-size: 16px;
            text-align: center;
            height: 32px;
            max-width: 24px;

            &:focus {
                outline: 1px solid #40a9ff;
                box-shadow: 0 0 0 2px #1890ff33;
                border-color: transparent;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    .codeInputInvalid {
        input {
            background-color: #f2dede;
            color: #b94a48;
            border: 1px solid #eed3d7;

            &:focus {
                outline: 1px solid #ff4d4f;
                box-shadow: 0 0 0 2px #ff4d4f33;
                border-color: transparent;
            }
        }
    }
}

.attemptsContainer {
    width: fit-content;
    color: #686a6e;
}

.attemptsContainerMobile {
    position: absolute;
    top: 35px;
}

.errorMessage {
    color: #ff4d4f;
}

@media screen and (560px < width < 770px),
(max-width: 375px) {
    .verificationContainer {
        .codeContainer {
            flex-wrap: wrap;
            row-gap: 10px;
            column-gap: 10px;

            button {
                flex: 0 0 100%;
            }
        }
    }

    .attemptsContainerMobile {
        display: block;
        color: #686a6e;
        width: 28%;
        position: unset;
    }
}

@media screen and (max-width: 300px) {
    .attemptsContainerMobile {
        width: 100%;
    }
}

.sentMessageMessage {
    margin-bottom: 10px;
}