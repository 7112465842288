@import '../../../styles/globals.scss';

.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    h1 {
        font-size: 7.5em;
        margin: 15px 0px;
        font-weight: bold;
        color: #3A547F;
    }

    h2 {
        font-weight: bold;
        color: #3A547F;
    }
}

.goHomeButton {
    $parent: &;
    z-index: 1;
    overflow: hidden;
    background: transparent;
    position: relative;
    border-radius: 30px;
    border: 4px solid #3A547F;
    cursor: pointer;
    font-size: 1em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    font-weight: bold;
    margin: 5px 0px;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 0%;
        height: 100%;
        background: #3A547F;
        z-index: -1;
        transition: 0.2s ease;
    }

    &:hover {
        color: #FFFFFF;
        background: #3A547F;
        transition: 0.2s ease;

        &::before {
            width: 100%;
        }
    }

    a {
        display: flex;
        text-decoration: none;
        color: #000000;
        width: 150px;
        height: 50px;
        justify-content: center;
        align-items: center;

        @at-root #{$parent} {

            &:hover a,
            &:focus a {
                color: #FFFFFF;
            }
        }
    }
}

.imageContainer {
    position: relative;
    left: -50px;
    width: 50%;
}

.textContainer {
    width: 40%;
}

@media screen and (max-width: 768px) {

    .imageContainer,
    .textContainer {
        width: 100%;
    }
}

@media screen and (max-width: $MOBILE_WIDTH_THRESHOLD_VALUE) {
    .imageContainer {
        left: -20px;
    }

    .textContainer {
        text-align: center;
        padding: 0 20px;
    }
}