@import '../../../styles/globals.scss';

.navbar {
    min-height: 55px;
    max-height: 55px;
    //z-index: 1;  // z-index of parent element NavigationHeader limits z-index of child element BugReportButton.
    display: flex;
    align-items: center;
    padding: 0 20px 0 10px;
    background-color: #3A547F;
    box-shadow: 0 6px 8px 2px #e5e5e5;

    .reverseContainer,
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .reverseContainer {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.logoContainer {
    margin-right: 15px;
    img {
        border-radius: 100px;
    }
}

.mobileSidebarToggle {
    display: none;
}

.titleContainer {
    font-size: 24px;
    min-width: fit-content;
    color: #E9EDF3;
    margin-left: 15px;
}

.navigationItemsContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    column-gap: 15px;
    color: #E9EDF3;

    &>div {
        text-align: right;
    }
}

.logoutButton {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    font-size: 18px;
    cursor: pointer;
    position: relative;

    &:hover,
    &:focus {
        color: #E9EDF3;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 2px;
        background-color: #E9EDF3;
        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    }
}

@media (hover: hover) and (pointer: fine) {
    .logoutButton:hover::before {
        left: 0;
        right: auto;
        width: 100%;
    }
}

@media screen and (max-width: 750px) {
    .usernameContainer {
        display: none;
    }
}

@media screen and (max-width: $MOBILE_WIDTH_THRESHOLD_VALUE) {
    .navbar {
        padding: 0 15px;
        min-height: 55px;
        max-height: 55px;
    }

    .titleContainer {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto !important;
        margin-right: auto;
        width: 100px;
        font-size: 20px;
    }

    .mobileSidebarToggle {
        $parent: &;
        display: block;
        background-color: transparent;
        padding: 5px 15px 5px 0;
        outline: none;
        border: 1px solid transparent;
        border-radius: 5px;
        cursor: pointer;

        svg {
            color: #E9EDF3;
            vertical-align: middle;

            @at-root #{$parent} {

                &:hover svg,
                &:focus svg {
                    color: #FFFFFF;
                }
            }
        }
    }
}
