@import '../../../styles/animations.scss';

.photoTitle {
  font-size: 18px;
  font-weight: bold;
}

.errorMessage {
  color: #ff4d4f;
}

.sentMessageMessage {
  margin-bottom: 10px;
}

.requirementsList {
  div {
    font-size: 18px;
    font-weight: bold;
  }

  ul {
    margin-bottom: 0 !important;
    padding-left: 1rem;

    li:not(:last-child) {
      margin-bottom: 7px;
    }

    li::marker {
      content: "▸  ";
      color: #df0f32;
    }
  }
}

@media only screen {
  .requirementsList {
    ul {
      li:not(:last-child) {
        margin-bottom: 0;
      }

      li {
        margin-left: 3px;
      }
    }
  }
}