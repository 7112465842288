.disabledOffsetRow {
    color: #afafaf;
    pointer-events: none;
    background-color: #f5f5f5; 
}

.disabledInput {
    background-color: #ebebeb; 
}

