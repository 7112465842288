@import '../../../styles/globals.scss';

.container-footer,
.container-footer--mobile {
    width: 100%;
    min-height: 70px;
    padding: 3px 35px;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    background-color: #e5e5e5;
    color: #474747;
    position: relative;

    .information,
    .support {
        width: 45%;
    }

    .support {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: 100%;
        justify-content: space-between;
        text-align: right;

        .supportLinks {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        a {
            font-weight: normal !important;
            width: fit-content;
        }
    }
}

.container-footer--mobile {
    padding: 5px 10px;
    background-color: rgba(249, 249, 249, 0.85);

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, transparent, #818078, transparent);
    }

    .information,
    .support {
        width: 100%;
        text-align: center;
        height: fit-content;
    }

    .support {
        align-items: center;

        .supportLinks {
            align-items: center;
        }
    }
}