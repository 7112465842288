@import '../../../../styles/animations.scss';

.container {
    animation: slide-up .5s ease;
}

.banExpirationTimeContainer {
    margin-top: 50px;
    font-size: 25px;
    text-align: center;
}

.extra {
    width: 100%;
    text-align: center;
    color: #686a6e;
}