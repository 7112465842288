@mixin keyframes-animation($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@mixin prefix-property($name, $value) {
    -webkit-#{$name}: $value;
    #{$name}: $value;
}

@include keyframes-animation(smooth-appear) {
    0% {
        @include prefix-property(opacity, 0);
    }

    100% {
        @include prefix-property(opacity, 1);
    }
}

@include keyframes-animation(slide-up) {
    0% {
        @include prefix-property(opacity, 0);
        @include prefix-property(transform, translateY(20px));
    }

    100% {
        @include prefix-property(opacity, 1);
        @include prefix-property(transform, translateY(0));
    }
}

@include keyframes-animation(slide-down-disappear) {
    0% {
        @include prefix-property(opacity, 1);
        @include prefix-property(transform, translateY(0));
    }

    100% {
        @include prefix-property(opacity, 0);
        @include prefix-property(transform, translateY(20px));
    }
}

@include keyframes-animation(slide-down-appear) {
    0% {
        @include prefix-property(opacity, 0);
        @include prefix-property(transform, translateY(-20px));
    }

    100% {
        @include prefix-property(opacity, 1);
        @include prefix-property(transform, translateY(0));
    }
}