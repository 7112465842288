@import '../styles/globals.scss';

.layoutContainer {
    min-height: 100vh;
    height: 100%;
    background-color: rgb(249 249 249 / 85%) !important;

    section {
        background-color: transparent;
    }

    .siderAndContentContainer {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .mainContent {
        min-height: 100vh !important;
        padding: 50px 50px;
        flex: 1;
        width: 70%;
    }
}

@media only screen and (max-width: 1300px) {
    .layoutContainer {
        .mainContent {
            padding: 20px 20px 40px;
        }
    }
}

@media only screen and (max-width: $MOBILE_WIDTH_THRESHOLD_VALUE) {
    .layoutContainer {
        .mainContent {
            padding: 30px 10px;
        }
    }
}