.buttonsContainer {
    justify-self: center;
    display: flex;
    margin: auto;
    column-gap: 20px;
}

.innerButtonsContainer {
    display: flex;
    column-gap: 20px;
}

.menuButton {
    width: 165px;
    height: 35px;
    border-radius: 5px;
    font-size: 13px;
    line-height: 19px;
}

.disabledIcon {
    color: #afb6bb !important;
    pointer-events: none;
}

.menuButton:hover:enabled .icon {
    color: #1890ff;
    transition: 0.5s;
}

.menuButton:hover .icon,
.reportsDropdownItem:hover .icon {
    color: #1890ff;
    transition: 0.5s;
}

.menuButton:focus .icon {
    color: #1890ff;
    transition: 0.5s;
}

.menuButton:disabled .icon {
    color: #d9d9d9;
}

.pulsateButton {
    outline: 2px solid #40a9ff;
    animation: border-pulsate 2s infinite;

    &:hover {
        animation-play-state: paused; 
    }
}

@keyframes border-pulsate {
    0% {
        outline-color: #40a9ff;
    }

    50% {
        outline-color: transparent;
    }

    100% {
        outline-color: #40a9ff;
    }
}

.icon, .studentStatusLabel {
    color: #3A547F;
}

.iconMobile {
    color: #3A547F;
    margin-right: 36px;
    width: 26px;
    height: 26px;
    transition: all ease-out .2s;
    cursor: pointer;
}

.iconMobileSmallMargin {
    margin-right: 8px;
}

.iconMobile:hover {
    color: #5c8fc4;
}

.spaceItem {
    display: flex;
}

.reportsDropdownItem {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.reportsDropdownItemIcon {
    width: 20px;
    text-align: center;
}

.reportsDropdownItemText {
    padding-left: 8px;
}