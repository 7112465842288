@import "../../../styles/globals.scss";

.container {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 16px;
    width: 100%;

    .tabs {
        align-self: center;
        margin-top: 12px;
        max-width: 100%;
    
        label {
            width: 150px;
            text-align: center;
            overflow: hidden;
        }

        :global {
            .ant-segmented-group {
                background-color: #ebebeb;
                border-radius: 5px;
            }
        }
    }

    @property --x {
        syntax: '<percentage>';
        inherits: false;
        initial-value: 0%;
    }

    .backButton {
        cursor: pointer;
        align-self: center;
        color: #3a547f;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
    }

    .wave {
        animation: click-wave 0.3s;
        position: relative;
        background: radial-gradient(rgba(177, 169, 169, 0.7) var(--x), transparent var(--x));
        background-position: center;
    }
      
    @keyframes click-wave {
        100% {
          --x: 100%;
        }
    }
      

    .controlsPanel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $JOURNAL_MOBILE_HEADER_HEIGHT;

        .subjectName {
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            text-decoration: underline;
            text-decoration-thickness: 3px;
        }

        .studentGroup {
            margin-left: 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
        }
    }

    .tableContainer {
        overflow: hidden;
    }
    
    .tableContainer .pages {
        display: flex;
        width: 100%;

        .page {
            width: 100%;
            display: flex;
            transition: all 0.6s;
        }

        .slideRight {
            transform: translateX(-100%);
        }

        .slideLeft {
            transform: translateX(0);
        }
    }

    :global {
        .ant-segmented {
            border-radius: 5px;
        }
        
        .ant-segmented-group label:first-child {
            border-radius: 5px 0 0 5px;
        }
        
        .ant-segmented-group label:last-child {
            border-radius: 0 5px 5px 0;
        }
        
        .ant-segmented-item {
            border: 1px solid transparent;
        }
        
        .ant-segmented-item-selected,
        .ant-segmented-thumb,
        .ant-segmented-thumb-motion-appear,
        .ant-segmented-thumb-motion-appear-active .ant-segmented-thumb-motion,
        .ant-segmented-group > div {
            color: #0c63e4 !important;
            background-color: #e7f1ff;
            border: 1px solid #7ba9ed;
        }
        
        .ant-input-disabled {
            background-color: #f7f7f7;
        }

        .ant-collapse-content>.ant-collapse-content-box {
            padding: 0;
        }

        .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
            padding: 12px 0;
        }
    }

    .collapseContainer {
        margin-left: -10px;
        margin-right: -10px;
    }

    .panelContainer {
        text-align: center;
        font-size: 15px;

        :global {
            .ant-collapse-expand-icon {
                padding-right: 12px !important;
            }
        }
    }

    .firstSubGroup {
        background-color: #dcf5d6;
    }

    .secondSubGroup {
        background-color: #d6ebff;
    }
}

.tooltipProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  
    .photo {
      height: 100px;
    }
  }

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1300;
    touch-action: none;

    .loadingSpinner {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.assignSubgroups {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fioCell {
    text-align: left;
}

.markHeaderCell {
    display: flex;
    align-items: center;
}

.labCountDialog {
    :global {
        .ant-modal-body {
            display: none;
        }
        .ant-modal-header {
            border-bottom: none;
        }
        .ant-modal-footer {
            border-top: none;
        }
    }
}

.labInput {
    width: 78px;
    border-radius: 5px;
    margin-right: 5px;

    :global {
        .ant-input-number-input {
            height: 45px;
            font-size: 18px;
            text-align: center;
        }
    }
}

.mobileLegend {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}