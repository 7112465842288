@import '../../../../styles/animations.scss';

.goBackButton {
    background-color: transparent;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    position: absolute;
    top: 10px;
    left: 10px;
    color: #989a9b;
    cursor: pointer;

    svg {
        vertical-align: middle;
    }

    &:hover,
    &:focus,
    &:active {
        color: #747677;
        background-color: #efefef;
    }
}

.container {
    animation: slide-up .5s ease;

    &--unmounted {
        animation: slide-down-disappear .5s ease-in-out;
    }
}

.details {
    width: 100%;
    text-align: center;
    color: #686a6e;
    margin-bottom: 5px;
}

.contactValue {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    position: relative;
    top: 10px;
}

.maskInput {
    &>span {
        &>span {
            &:first-child {
                border-radius: 5px 0 0 5px;
            }

            &:last-child {
                border-radius: 0 5px 5px 0;
            }
        }
    }
}

.errorContainer {
    margin-bottom: 15px;
    animation: smooth-appear .5s ease;
}