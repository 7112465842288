.buttonWrapper {
    background-color: #FFFFFF;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 1rem;
    bottom: 60px;
    z-index: 10;
    border-radius: 0.5rem;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 1%), 1px 1px 5px rgb(0 0 0 / 15%);
}

.buttonWrapper button {
    z-index: 1;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    background-color: transparent;
    outline: none;
    border: none;
}

.buttonWrapper button:hover {
    background-color: #6786B8;
}

.buttonWrapper button:hover svg {
    color: #FFFFFF;
}
   
.excalidrawWrapper {
    height: 800px;
    position: relative;
}

  :root[dir="ltr"] .excalidraw .layer-ui__wrapper .zen-mode-transition.App-menu_bottom--transition-left {
    transform: none
  }

  .excalidraw .panelColumn {
    text-align: left;
  }

  .exportWrapper {
    display: flex;
    flex-direction: column;
    margin: 50px;
  }

@media only screen and (max-width: 892px) {
    .buttonWrapper {
        bottom: 85px;
        left: 15px;
    }
}
