.tableContainer {
  box-sizing: border-box;
  width: 100%;
  background: #FFFEFE;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.table {
  :global {
    .ant-table, .ant-table-header, .ant-table-body {
      border-radius: 5px;
    }

    .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
      padding: 0;
    }

    .ant-table-tbody>tr>td {
      border: 1px solid #e9e9e9;
    }

    .ant-table-tbody>tr.ant-table-row>td.ant-table-cell .ant-input, .ant-table-tbody>tr.ant-table-row>td.ant-table-cell {
        transition: none;
    }

    .ant-table-tbody > tr > td.ant-table-cell .ant-input:focus {
      box-shadow: none !important;
    }

    .ant-table-tbody > tr.ant-table-row > td div:focus-within {
      box-shadow: 0 0 0 1.5px rgba(5, 145, 255, 0.3);
      border: 1.5px solid #0591FF;
    }

    .ant-table-tbody > tr.ant-table-row:not(.ant-table-row-selected):hover :is(td.ant-table-cell, td.ant-table-cell .ant-input) {
      background-color: #e6f7ff !important;
    }

    .ant-table-tbody > tr.ant-table-row:has(td.ant-table-cell:not(.ant-table-selection-column):focus-within) :is(td.ant-table-cell-fix-left, td.ant-table-cell-fix-left div) {
      background-color: #d4ecff !important;
    }

    .ant-table-thead > tr > th:not(.ant-table-cell-fix-left):hover {
      background-color: #e6f7ff !important;
    }

    .ant-table-tbody > tr.ant-table-row-selected :is(td.ant-table-cell-fix-left, td.ant-table-cell-fix-left div) {
      background-color: #d4ecff !important;
    }

    .ant-table-tbody > tr.ant-table-row-selected > td > div {
      background-color: #e6f4ff !important;
    }
  }
}

.disabledInput  {
  opacity: 0.5;
  background-color: #67676717 !important;

  &:hover {
    cursor: not-allowed;
  }
}

.disabledTable {
  :global {
    opacity: 0.5;
    background-color: #67676717 !important;

    .ant-table-thead > tr > th {
      color: #afafaf;
    }
  }

  &:hover {
    cursor: not-allowed;
  }

  .omissionContainer,
  .hiddenOmissionContainer,
  .markInput {
    color: #afafaf !important;
  }

  .tooltipSign {
    border-top: 10px solid #afafaf !important;
  }
}

.disabledRow, .disabledOffsetRow {
  color: #afafaf;
  pointer-events: none;
}

.disabledRow {
  background-color: #ffffff;
}

.disabledOffsetRow {
  background-color: #f5f5f5;
}

.inOffsettingCell {
  height: 30px;
  letter-spacing: 5px;
  font-size: large;
  color: #3A547F;
}

.inputContainer {
  display: flex;
  :global {
    .ant-input {
      border: none;
    }
  }
}

.markInput {
  text-align: center;
  padding: 4px 0;
  width: 100%;
  border: none;
  outline: 0;
  background: transparent;
}

.markContainer {
  flex-grow: 1;
  padding: 5px 0;
  white-space: nowrap;
}

.calloutContainer {
  position: relative;
}

.tooltipSign {
  position: absolute;
  border-top: 10px solid #6185be;
  border-left: 11px solid transparent;
  right: 0;
  top: 0;
}

.omissionContainer, .marksContainer, .hiddenOmissionContainer, 
.hiddenMarksContainer, .respectfulOmissionContainer, .hiddenRespectfulOmissionContainer {
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  width: max-content;
  height: 10px;
  font-size: 12px;
  right: 2px;
}

.omissionContainer, .hiddenOmissionContainer {
  color: red;
}

.respectfulOmissionContainer, .hiddenRespectfulOmissionContainer {
  color: green;
}

.hiddenOmissionContainer, .hiddenMarksContainer {
  left: 0;
}

.currentDate {
  background-color: #e6f7ff;
  color: #1890ff;
  border-top: 1px solid #1890ff;
  border-bottom: 1px solid #1890ff;
  position: relative;
}

.selectedColumn {
  background-color: #d4ecff;
  position: relative;
}

.lessonHeader {
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  height: 44px;
}

.tooltipProfile {
  display: flex;
  flex-direction: column;
  align-items: center;

  .photo {
    height: 100px;
  }
}

.fioCell {
  cursor: pointer;
  padding-left: 5px;
  text-align: left;
}

.selectLabNumber {
  width: 52px;
  :global {
    .ant-select-selector {
      border-radius: 5px !important;
    }
  }
}

.tableLegend {
  display: flex;
    flex-direction: column;
    align-items:flex-start;
    margin-top: 5px;
    margin-left: 5px;
  width:'400px';
}

.tableLegendRespectOmission{
  display: flex;
    flex-direction: row;
}

.selectLabNumberOption {
  text-align: center;
}