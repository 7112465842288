.timerWithLabelsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.timerValue {
    min-width: 90px;
    text-align: center;

    div:first-child {
        font-size: 100%;
    }

    div:last-child {
        font-size: 60%;
        line-height: 10px;
    }
}

.separator {
    padding-top: 10px;
}