.select {
  :global {
    .ant-select-selector {
      border-radius: 5px !important;
    }
  }
}

.selectOption {
  text-align: center;
}

.textArea {
  border-radius: 5px !important;
}