@import '../../../styles/animations.scss';

.imageContainer {
  border-radius: 200px !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px auto;
}

.changePhoto {
  position: relative;
  display: inline-block;
}

.image {
  display: block;
  width: 200px;
  height: 200px;
}

.overlay {
  transition: all .3s ease;
  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  transform: translate(50%, -100%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
}

.overlay:hover .icon {
  color: #1890ff;
}

.icon {
  color: #3A547F;
  font-size: 38px;
  transform: translate(0%, 25%);
  transition: all .3s ease;
}

.photoCheckbox {
  margin: 15px 0;

  :global {
    .ant-checkbox {
      align-self: flex-start;
      padding-top: 3px;
    }
  }
}

.photoForm {
  & > div {
    margin: 0 0 15px;
  }

  input {
    &:disabled {
      color: gray !important;
    }
  }
}

.animateAppearance {
  animation: slide-up .5s ease;
}

.cancelButton {
  padding: 5px 10px !important;
  cursor: pointer;
  border-radius: 5px !important;
  transition: all .2s ease-in;
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    background-color: #d5d4d4 !important;
    border-color: #d5d4d4 !important;
    color: #000000 !important;
  }
}