.scrollUpButtonContainer {
    position: fixed;
    width: 50px;
    right: 20px;
    bottom: 80px;
    height: 50px;
    z-index: 1;
}

.scrollUpButton {
    position: fixed;
    width: 50px;
    right: 20px;
    bottom: 80px;
    height: 50px;
    z-index: 1;
    cursor: pointer;
    color: #FFFFFF;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3A547F;
    border: 1px solid #3A547F;

    &:hover {
        background-color: #6786B8;
        border-color: #6786B8;
    }
}