.container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    border-radius: 16px;
    box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
    background-color: white;
    margin-bottom: 16px;
    padding: 12px;
    color: #474747;
    overflow: hidden;

    hr {
        margin-bottom: 10px;
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid rgb(211, 211, 211);
        padding: 0;
    }
}

.container > .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 6px;

    .announcementDate {
        font-size: 24px;
        margin-right: 8px;
    }

    .announcementTime {
        font-size: 18px;
        margin-left: 4px;
    }

    .clockIcon {
        color: #3d547e;
    }
}

.announcementAuditory {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    white-space: nowrap;
    font-size: 13px;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.container .announcementDescription {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;
    margin-bottom: 18px;
}

.container .announcementAlreadyPassed {
    color: #a3a4a9 !important;
}

.alreadyPassedText {
    color: #b7bbc2 !important;
}

.container span.groupTag {
    width: fit-content;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #D4ECFF;
    box-shadow: rgb(0 0 0 / 2%) 0 1px 3px 0, rgb(24 144 255 / 20%) 0 0 0 1px;
    border-radius: 10px;
    font-size: 15px;
    margin-bottom: 8px;
    margin-left: 6px;
}

.alreadyPassed {

    span.groupTag {
        background-color: #ddecfa;
        color: #b7bbc2;
    }
}

.kebabButton {
    width: 17px !important;
    padding-top: 4px !important;
}

.kebabSubButton {
    display: flex;
    align-items: center;
    padding: 0;
}