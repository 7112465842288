.bugReportButton {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    outline: none;
    border-radius: 15px;
    z-index: 5000 !important;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 0.2s ease;
    text-decoration: none;
    padding: 5px 10px;
    color: #E9EDF3;
    border: 1px solid #6786B8;
    font-size: 16px !important;

    &:disabled {
        z-index: inherit !important;
    }

    &:hover:enabled {
        transition: 0.2s ease;
        color: #FFFFFF;
        background: #6786B8;

        &::before {
            width: 100%;
        }
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        z-index: -1;
        transition: 0.2s ease;
        background: #6786B8;
    }

    &>div {
        margin-left: 5px;
    }
}

.mobileBugReportButton {
    z-index: 5000 !important;
    background-color: transparent;
    outline: none;
    border: none !important;
    display: none;
    color: #E9EDF3;

    &:hover,
    &:active {
        color: #FFFFFF;
    }

    &:disabled {
        z-index: inherit !important;
    }
}


@media screen and (max-width: 1100px) {
    .bugReportButton {
        display: none;
    }

    .mobileBugReportButton {
        display: block;
        position: relative;
        top: 3px;
    }
}
