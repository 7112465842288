@import "../../../../styles/globals.scss";

.container {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 16px;
    max-width: 100vw;
}

.container .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $JOURNAL_MOBILE_HEADER_HEIGHT;
}

.subjectName {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    margin: 0 8px;
    text-decoration: underline;
    text-decoration-thickness: 3px;
}

@property --x {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 0%;
}

.backButton {
    cursor: pointer;
    align-self: center;
    color: #3a547f;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.wave {
    animation: click-wave 0.3s;
    position: relative;
    background: radial-gradient(rgba(177, 169, 169, 0.7) var(--x), transparent var(--x));
    background-position: center;
}

@keyframes click-wave {
    100% {
        --x: 100%;
    }
}
