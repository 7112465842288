.container, .centeredContainer {
    border-radius: 5px;
    height: 84vh;
    background-color: #FFFFFF;
    overflow-y: auto;
    box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
}

.centeredContainer {
    width: 40%;
    margin: auto;
    height: 100%;
}

.header {
    height: 70px;
    border-radius: 5px 5px 0 0;
    color: #3A547F;
    font-weight: bold;
    font-size: 22px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.header button {
    background-color: transparent;
    border: none;
    outline: none;
}

.imageContainer {
    width: 250px !important;
    border-radius: 500px !important;
    margin: 20px auto;
}

.photoCheckbox {
    :global {
        .ant-checkbox {
            align-self: flex-start;
            padding-top: 3px;
        }
    }
}

.details {
    padding: 10px 30px;
}

.personalInformationContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.personalInformationContainer > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.sectionTitle {
    font-weight: bold;
    font-size: 18px;
    color: #3A547F;
    margin-bottom: 7px;
    width: 100%;
}

.buttonAdditionalInformation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 1370px) {
    .buttonAdditionalInformation {
        display: block;
        text-align: right;
        align-items: center;
    }
}

.underline {
    text-decoration: none;
    color: #3A547F;
    position: relative;
    cursor: pointer;
    font-weight: normal;
}

.underline:hover, .underline:focus {
    color: #2d6ea7;
}

.underline::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    background-color: #2d6ea7;
    transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.contact div {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.contact img, .links img {
    margin-right: 10px;
}

.headerTab {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bodyTab {
    width: 100%;
}

@media only screen and (max-width: 1390px) {
    .info {
        display: block;
    }

    .fullWidth {
        padding-left: 0;
    }

    .contactsGrid {
        border-top: 1px solid rgb(154, 154, 154);
        margin-top: 10px;
        padding-top: 10px;
    }
}

@media only screen and (min-width: 1390px) {
    .fullWidth {
        padding-left: 20px;
    }

    .info {
        background: linear-gradient(rgb(154, 154, 154), rgb(154, 154, 154)) top/1px 93% no-repeat;
        display: grid;
        grid-template-areas:
            "jobPosition contacts";
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
}


.jobPosition {
    grid-area: jobPosition;
}

.contactsGrid {
    grid-area: contacts;
}

.headerName {
    font-size: 17px;
    font-weight: 450;
}

.button {
    border: none;
    background-color: transparent;
    font-size: 15px;
}

.addButton {
    border: none;
    background-color: transparent;
    color: #3A547F;
    transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.addButton:hover, .addButton:focus {
    color: #2d6ea7;
}

.studListReferences {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-row-gap: .5em;
}

.studListReferences a {
    display: block;
    width: fit-content;
}

.referenceName {
    margin-left: 5px;
}

.references {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectContainer {
    margin-bottom: 10px;
}

.selectName {
    margin-bottom: 5px;
}

.selectButton {
    border-radius: 5px;
    width: 100%;
}

.cancelButton {
    cursor: pointer;
    border-radius: 5px !important;
    transition: all .2s ease-in;
    animation: slide-up .5s ease;
    margin: 0 15px;
}

@media only screen and (max-width: 1370px) {
    .cancelButton {
        margin: 0 0 10px 0;
    }
}

.cancelButton:hover, .cancelButton:active, .cancelButton:focus {
    background-color: #d5d4d4 !important;
    border-color: #d5d4d4 !important;
    color: #000000 !important;
}

.submitButton {
    background-color: #6786b8 !important;
    border: 1px solid #6786b8 !important;
    color: #FFFFFF !important;
    cursor: pointer;
    border-radius: 5px !important;
    transition: all .2s ease-in;
    animation: slide-up .5s ease;
}

.submitButton:hover, .submitButton:active, .submitButton:focus {
    background-color: #3a547f !important;
    border-color: #3a547f !important;
    color: #FFFFFF !important;
}

@media (hover: hover) and (pointer: fine) {
    .underline:hover::before {
        left: 0;
        right: auto;
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .centeredContainer {
        width: 75%;
    }
}

@media only screen and (max-width: 550px) {
    .centeredContainer {
        width: 95%;
    }
}
@media only screen and (max-width: 500px) {
    .centeredContainer {
        margin-top: 20px;
    }
}