.sidebar-desktop-tooltip {
    left: 65px !important;

    div[role=tooltip] {
        border-radius: 5px !important;
    }
}

@media screen and (max-width: 575px) {
    .ant-form-item .ant-form-item-label {
        padding: 0;
    }
}