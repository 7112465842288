.selectContainer {
  margin-bottom: 10px;
}

.selectName {
  margin-bottom: 5px;
}

.selectButton {
  border-radius: 5px;
  width: 100%;
  :global {
    .ant-select-selector {
      border-radius: 5px !important;
    }
  }
}

.deleteButton {
  position: absolute;
  bottom: 20px;
  svg {
    margin-right: 5px;
  }
}