@import '../../../../styles/animations.scss';

.container {
    animation: slide-up .5s ease;

    &--unmounted {
        animation: slide-down-disappear .5s ease-in-out;
    }
}

.codeContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &>div {
        &:first-child {
            flex-basis: 180px;
            flex-grow: 9999;
        }
    }

    &>button {
        flex-basis: 100px;
        flex-grow: 1;
    }
}

.timerContainer {
    flex-basis: 114px;
    flex-grow: 1;
    border: 1px solid #7ba9ed;
    height: 32px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.codeInputContainer,
.codeInputContainerInvalid {
    input[type=number] {
        -moz-appearance: textfield;
    }

    input {
        box-shadow: none;
        font-family: monospace;
        border-radius: 5px;
        border: 1px solid lightgrey;
        margin-right: 5px;
        font-size: 16px;
        text-align: center;
        height: 32px;
        max-width: 24px;

        &:focus {
            outline: 1px solid #40a9ff;
            box-shadow: 0 0 0 2px #1890ff33;
            border-color: transparent;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.codeInputContainerInvalid {
    input {
        background-color: #f2dede;
        color: #b94a48;
        border: 1px solid #eed3d7;

        &:focus {
            outline: 1px solid #ff4d4f;
            box-shadow: 0 0 0 2px #ff4d4f33;
            border-color: transparent;
        }
    }
}

.attemptsContainer {
    width: fit-content;
    color: #686a6e;
}

.errorMessage {
    color: #ff4d4f;
}

@media screen and (max-device-width: 350px) {
    .codeContainer {
        &>div {
            &:first-child {
                display: flex;
                margin-bottom: 15px;
            }
        }
    }

    .attemptsContainer {
        width: 85px;
        position: relative;
        left: 13px;
        line-height: 16px;
    }
}

@media screen and (max-device-width: 318px) {
    .codeContainer {
        &>div {
            &:first-child {
                flex-direction: column;
            }
        }
    }

    .attemptsContainer {
        width: 100%;
        position: unset;
        margin-top: 10px;
    }
}