@import "../../../styles/globals.scss";

.dateButton {
    box-shadow: rgb(0 0 0 / 5%) 0 1px 2px 0;
    min-height: 40px;
    font-size: 16px;
    background-color: #FFFFFF;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 100%;
}

.dateButtonAnimation {
    animation: filling 0.2s ease;
}

@keyframes filling {
    100% {
        background-color: #d9d9d9;
    }
}

.statusDate {
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    height: $DATE_BUTTON_STATUS_DATE_HEIGHT;
    padding-top: 2px;
}

.wide {
    width: 100%;
    margin: 0 5px;
}

.bold {
    font-weight: bold;
}