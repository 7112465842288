.weekCheckbox {
  margin: 0 !important;
}

.repeatCheckbox {
  font-size: 14px !important;
  :global {
    .ant-checkbox+span {
      padding-right: 5px !important;
    }
    .ant-checkbox-disabled+span {
      color: rgba(0,0,0,.85);
    }
  }
}

.repeatContainer {
  padding: 20px 10px;
}

.repeatField {
  width: 100px;
}

.repeatSelectDate {
  border-radius: 5px;
}

.repeatDateContainer {
  display: inline-block;
}

.repeatPanel {
  :global {
    .ant-collapse-header {
      padding: 0 !important;
    }
    .ant-collapse-arrow {
      display: flex !important;
      margin: 0 !important;
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
}

@media screen and (min-width: 500px) {
  .repeatField {
    display: inline-block;
  }
}

@media screen and (max-width: 500px) {
  .repeatContainer {
    padding: 10px 24px 0 24px;
  }
  .repeatSelectDate {
    width: 135px !important;
  }
}

@media screen and (max-width: 441px) {
  .repeatDateContainer {
    width: 100%;
  }
  .repeatSelectDate {
    width: 85% !important;
  }
}