.container {

    &--closed,
    &--opened {
        box-sizing: border-box;
        z-index: 1000 !important;
        width: 70px;
        min-width: 70px;
        -webkit-transition: width .3s ease-in-out;
        -moz-transition: width .3s ease-in-out;
        -o-transition: width .3s ease-in-out;
        transition: width .3s ease-in-out;
        font-size: 1rem;
        background-color: #e5e5e5;
        box-shadow: 7px 2px 8px -1px #efefef;
        display: flex;
        flex-direction: column;
    }

    &--opened {
        width: 260px;
        box-sizing: border-box;
        z-index: 1200;
    }

    &-mobile--closed,
    &-mobile--opened {
        min-width: 270px;
        width: 80%;
        max-width: 380px;
        transition: transform 0.3s ease-out;
        -webkit-transition: transform .3s ease-in-out;
        -moz-transition: transform .3s ease-in-out;
        -o-transition: transform .3s ease-in-out;
        transform: translateX(-110%);
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        box-sizing: border-box;
        z-index: 1000 !important;
        font-size: 1rem;
        background-color: #efefef;
        display: flex;
        flex-direction: column;
    }

    &-mobile--opened {
        transform: translateX(0%);
    }
}

.logoContainer {
    height: 70px;
    background-color: #6786B8;

    a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        margin-left: 10px;
        text-decoration: none;

        img {
            border-radius: 100px;
        }
    }

    span {
        color: #FFFFFF;
        font-size: 18px;
        margin-left: 20px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        max-width: 65%;
    }
}

.mobileProfileDataContainer {
    background-color: #3A547F;

    a {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        padding: 15px 10px 10px 15px;
    }

    .userData {
        margin-top: 10px;
        color: #FFFFFF;

        &>div {
            @extend .navigationItemText;
            margin: 0 !important;
            font-size: 18px;

            &:last-child {
                font-size: 14px;
                color: #dfe1f3;
            }
        }
    }
}

.navigationItems,
.navigationItemsMobile {
    position: sticky;
    top: 0;
    color: white;

    .container-mobile--opened & {
        position: unset;
    }

    a {
        border: none;
        display: flex;
        text-align: left;
        align-items: center;
        flex-direction: row;
        width: 100%;
        text-decoration: none;
        transition: all .2s ease-in;
        padding: 7px 5px 0 5px;
        background-clip: content-box;
        border-radius: 15px;

        .navigationItemIcon {
            border-radius: 100px;
            border: none;
            width: 50px;
            max-width: 50px;
            min-width: 50px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 4px;
            transition: all .2s ease-in;
            background-color: transparent;

            svg {
                transition: all .2s ease-in;
                color: #404040;
            }
        }

        .navigationItemText {
            width: 0;
            -webkit-transition: width .3s ease-in-out;
            -moz-transition: width .3s ease-in-out;
            -o-transition: width .3s ease-in-out;
            transition: width .3s ease-in-out;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            color: #000000;
            font-size: 15px !important;

            .container--opened &,
            .container-mobile--opened & {
                width: 100%;
            }
        }

        &:hover {
            background-color: #d4d5d5 !important;

            svg {
                color: #000000 !important;
            }

            .navigationItemIcon {
                background-color: transparent !important;
            }
        }
    }

    .selectedNavigationItem {

        &,
        &:hover {
            background-color: #bed5e7 !important;
            color: #dfe1f3;

            .navigationItemText,
            svg {
                color: #1677ff !important;
            }

            .navigationItemIcon {
                background-color: transparent !important;
            }
        }
    }
}

.toggleContainer {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 50px;

    button:not(:disabled) {
        cursor: pointer;
    }

    button {
        background-color: #3A547F;
        color: #FFFFFF;
        border: none;
        width: 100%;
        height: 100%;
        transition: all .25s ease-in-out;

        .toggleArrow {
            transform: scale(-1, 1);
        }

        svg {
            vertical-align: middle;
            transition: all 0.50s;
        }
    }

    .container--closed & button,
    .container--opened & button {
        -webkit-appearance: none;
        background-color: #d4d5d5;
        color: #404040;

        &:hover,
        &:focus {
            background-color: #c7c7c7;
        }
    }

    .container-mobile--closed &,
    .container-mobile--opened & {
        bottom: 15px;
        text-align: center;
        padding: 0 5px;

        button {
            border-radius: 15px;

            &:hover,
            &:focus {
                background-color: #273957;
            }
        }
    }
}