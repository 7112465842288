@import '../../../styles/animations.scss';

.controlsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.errorContainer {
    animation: smooth-appear .5s ease;
}