.cancelButton {
    padding: 5px 10px !important;
    cursor: pointer;
    border-radius: 5px !important;
    transition: all .2s ease-in;
    border-color: #7e8891;
    color: #7e8891;

    &:disabled {
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: #d9d9d9 !important;
        background: #f5f5f5 !important;
    }
}