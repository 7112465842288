.container {
  width: 100%;
  position: relative;
  margin-top: -10px;
  margin-bottom: -10px;
}

.teacherIcon {
  position: absolute;
  z-index: 1;
  top: 47px;
  left: 10px;
  height: 23px;
  width: 23px;
  color: rgb(64, 94, 139);
}

.filtersContainer {
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
}

.filtersPartContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  column-gap: 20px;
  margin-bottom: 5px;
  padding-top: 15px;
}

.filtersWithAlertContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 10px 20px;
}

.subgroupSelectContainer {
  width: 230px;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 15px;
}

.filterSelectContainer {
  width: 210px;
  display: inline-block;
  margin-bottom: 10px;
}

.filterNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.filterSelectName {
  font-size: 14px;
  line-height: 14px;
  color: #636363;
}

.filterSelector {
  width: 100%;

  :global {
    .ant-select-clear {
      top: 45%;
      right: 15px;
    }

    .ant-select-selector {
      min-height: 40px;
      border-radius: 5px !important;
      padding: 5px 15px !important;
    }
    .ant-select-arrow {
      padding-top: 8px;
    }
  }
}

.teacherSelector {
  width: 210px;

  :global {
    .ant-select-clear {
      top: 45%;
      right: 15px;
    }

    .ant-select-selector {
      min-height: 40px;
      border-radius: 5px !important;
      padding: 5px 15px 5px 40px !important;
    }

    .ant-select-selector .ant-select-selection-search {
      inset-inline-start: 38px;
    }

    .ant-select-arrow {
      padding-top: 8px;
    }
  }
}
