.container {
    max-width: calc(1100px - 10vw);
    min-height: 380px;
    border-radius: 5px;
    margin: auto;
    display: flex;
    flex-direction: row;
}

.tabList {
    width: 100%;

    div[role=tablist] {
        &::before {
            border-color: #c7c7c7;
        }
    }

    div[role=tab] {
        font-size: 18px;

        svg {
            margin-right: 5px;
            position: relative;
            top: 2px;
        }
    }
}

.tabContainer {
    display: flex;
    flex-direction: row;
    column-gap: 20px;

    .tabSideCaption {
        width: 220px;
        min-width: 150px;
        padding-right: 15px;
        border-right: 1px solid #c7c7c7;
    }

    .tabContent {
        flex-grow: 1;
    }
}

.alertCard {
    padding: 0 2rem;
    margin-top: 20px;
}

.alert {
    max-width: 900px;
    margin: 0 auto 10px;
    padding: 5px 20px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    color: #490000;
    background: #ebcccc;
}

.alertHeader {
    font-size: 28px;
    color: red;
}

@media only screen and (max-width: 570px) {
    .tabList {
        div[role=tab] {
            font-size: 16px;
        }
    }

    .tabContainer {
        flex-direction: column;
        column-gap: 0;
        row-gap: 15px;

        .tabSideCaption {
            width: 100%;
            border-right: none;
        }
    }
}