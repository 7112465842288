.labCount {
    height: 33px;
    justify-self: end;
    :global {
        .ant-input-number-group {
            width: 160px;
        }

        .ant-input-number {
            padding-top: 2px;
            margin-left: 10px;
        }

        .ant-input-number-group-addon {
            padding: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .ant-input-number-affix-wrapper {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }
}

.labCountButton {
    :global {
        height: 17px;
    }
}