.container {
    position: relative;
    overflow: hidden;
}

span.ripple {
    position: absolute; 
    border-radius: 50%;
    transform: scale(0);
    animation: ripple_anim 250ms linear;
    background-color: rgba(177, 169, 169, 0.7);
}

@keyframes ripple_anim {
    100%
    {
        transform: scale(2);
        opacity: 0;
    }
}