@import './styles/commonStyles.scss';

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .9);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 1000px white inset !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.ant-input-affix-wrapper {
  border-radius: 5px;
  border: 1px solid #dae0e9;

  .ant-input-prefix {
    color: #2c2e30;
  }
}

.ant-alert-error {
  background-color: #fdd5cf;
  border-color: #d57970;
  border-radius: 5px;
}

