.card {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  width: 100%;
  height: 68px;
  padding: 6px 20px;
  margin-top: 10px;
  background: #ffffff;
  border-radius: 10px;
}

.groupName {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}

.groupAttendance {
  color: rgba(83, 83, 83, 0.85);
  font-size: 14px;
  margin-left: 4px;
}