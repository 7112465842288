.container {

  :global {
    .ant-btn-primary {
      display: inline-flex;
      align-items: center;
      padding: 4px 30px;
      border-radius: 5px;
      height: 40px;
      background-color: #1890ff;
      border: 1px solid #1890ff;
      color: #FFFFFF;

      &:hover,
      &:active,
      &:focus {
        background-color: #3758b6;
        border-color: #3758b6;
      }
    }

    .ant-btn-default {
      display: inline-flex;
      align-items: center;
      border-radius: 5px;
      height: 40px;

      &:hover,
      &:active,
      &:focus {
        border-color: #3758b6;
      }
    }

    .ant-modal-title {
      font-size: 18px;
    }
  }

  .noteInput {
    font-size: 18px;
    resize: none;
    border-radius: 8px;
    margin-bottom: 10px;
  }
}