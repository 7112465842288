.addRowButtonContainer {
    display: flex;
    text-align: right;
    align-items: center;

    button {
        width: 105px;
    }
}

@media only screen and (max-width: 1370px) {
    .addRowButtonContainer {
        display: block;
    }
}

.tableContainer {
    margin-bottom: 15px;

    table {
        th {
            text-align: center;
            font-weight: bold;
            color: #3A547F;
        }

        td {
            text-align: center;

            div[role=alert] {
                text-align: left;
            }
        }
    }

    input {
        border-radius: 5px;
    }
}

.editControlsContainer {
    display: flex;
    justify-content: space-around;

    button {
        padding: 5px;
        border-radius: 100px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        color: #3A547F;

        svg {
            position: relative;
            top: 2px;
        }

        &:hover,
        &:active,
        &:focus {
            outline: none;
            background-color: #e7f1ff;
        }

        &:disabled {
            cursor: not-allowed;
            background-color: transparent;
            color: #bfc6d1;
        }
    }
}