.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.mobileContainer {
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.positionsContainer {
  box-sizing: border-box;
  width: 100%;
}

.tableContainer {
  max-height: calc(75vh);
  overflow: auto;
  width: 100%;
  background: #FFFEFE;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.noSearchContainer {
  max-width: 300px;
  margin: 200px auto auto;
  text-align: center;
}

.tableHeader {
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 22px;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.88);
}

.noPositions {
  color: #808285;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.teachingLoadContainer > .controls  {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.filterSelectName {
  margin-bottom: 10px;
  margin-left: 5px;
  font-size: 14px;
  line-height: 14px;
  color: #636363;
}

.studyYearSelector {
  width: 145px;
  height: fit-content;

  :global {
    .ant-select-selector {
      min-height: 38px;
      border-radius: 5px !important;
      padding: 0 15px !important;
      font-size: 15px;
    }
  }
}

.downloadButton {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 5px;
  height: 38px;
  background-color: #6786b8 !important;
  border: 1px solid #6786b8 !important;
  color: #FFFFFF !important;
  font-size: 15px;

  &:hover,
  &:active,
  &:focus {
    background-color: #3a547f !important;
    border-color: #3a547f !important;
  }
}