@import '../../../styles/globals.scss';

.extra {
    width: 100%;
    text-align: center;
    color: #686a6e;
}

.requirementsList {
    div {
        font-size: 18px;
        font-weight: bold;
    }

    ul {
        margin-bottom: 0 !important;
        padding-left: 1rem;
        --marker-color: #fa0d20 !important;

        li:not(:last-child) {
            margin-bottom: 7px;
        }

        li::marker {
            content: "▸  ";
            color: var(--marker-color);
            transition-duration: 0.5s;
        }
    }
}

@media only screen and (max-width: $MOBILE_WIDTH_THRESHOLD_VALUE) {
    .requirementsList {
        ul {
            li:not(:last-child) {
                margin-bottom: 0;
            }

            li {
                margin-left: 3px;
            }
        }
    }
}