.formContainer {
  padding-left: 10px;
  font-size: 20px;
  display: inline-block;
}

.countLabs {
  margin-bottom: 20px;
}

.numberInput {
  margin-left: 20px;
  height: 30px;
  width: 103px;
  text-align: center;
  font-size: 15px;
}

.radioButtons {
  margin: 20px 20px 20px 0px;
}

.radio {
  margin-left: 10px;
}