.commentIcon {
  color: #3A547F;
  justify-content: center;
  display: flex;
}

.commentText {
  font-size: 14px;
  padding-right: 4px;
}

.comment {
  display: flex;
  align-items: center;
}

.comment:hover .commentText, .comment:hover .commentIcon {
  color: #1890ff;
}

.comment_disabled {
  color: gray !important;
  cursor: not-allowed;
}
