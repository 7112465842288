.table {
  display: block;
  width: 100vw;

  .disabledRow {
    color: #afafaf;
    pointer-events: none;
    background-color: #f5f5f5;
  }

  .inOffsettingCell {
    letter-spacing: 5px;
    font-size: medium;
    color: #3A547F;
  }

  .markInputCell, .omissionsInputCell, .labMarkInputCell {
    height: 50px;
    text-align: center;
    font-size: 22px;
  }

  .labMarkInputCell {
    width: 52px;
    border-right: none;
    padding: 0;
  }

  .labNumberContainer {
    width: 50px;
    border: 1px solid #d9d9d9;
    background-color: #D4ECFF;
    padding: 6px 0;
  }

  .selectLabNumber {
    :global {
      .ant-select-selector {
        background-color: inherit;
        width: 50px;
        font-size: 15px;
        padding: 0 8px;
        height: 36px;
        align-items: center;
        border: none;
        box-shadow: none !important;
      }
    }
  }

  :global {
    .ant-table-tbody, .ant-table-thead > tr > th {
      padding: 8px 12px;
      white-space: nowrap;
    }
  }
}

.checkBox {
  margin-right: 18px;
  margin-left: 12px;
  scale: 1.8;
}

.respectfulIcon {
  margin-left: 4px;
}

.omissionHours {
  width: 65px;

  :global {
    .ant-select-selector {
      color: red;
    }
  }
}

.respectOmissionHours {
  width: 65px;

  :global {
    .ant-select-selector {
      color: green;
    }
  }
}

.noteButton {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.animatedButton {
  animation-name: shrink;
  animation-duration: 0.2s;
}

@keyframes shrink {
  from {
    scale: 1;
  }

  to {
    scale: 0.8;
  }
}