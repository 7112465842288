/* lora-regular - cyrillic */
@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/lora-v20-cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/lora-v20-cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/lora-v20-cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/lora-v20-cyrillic-regular.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-600 - cyrillic */
@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/lora-v20-cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/lora-v20-cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/lora-v20-cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/lora-v20-cyrillic-600.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-500 - cyrillic */
@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/lora-v20-cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/lora-v20-cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/lora-v20-cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/lora-v20-cyrillic-500.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-italic - cyrillic */
@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/lora-v20-cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/lora-v20-cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/lora-v20-cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/lora-v20-cyrillic-italic.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-700 - cyrillic */
@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/lora-v20-cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/lora-v20-cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/lora-v20-cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/lora-v20-cyrillic-700.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-600italic - cyrillic */
@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/lora-v20-cyrillic-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/lora-v20-cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/lora-v20-cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/lora-v20-cyrillic-600italic.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-500italic - cyrillic */
@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/lora-v20-cyrillic-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/lora-v20-cyrillic-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/lora-v20-cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/lora-v20-cyrillic-500italic.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-700italic - cyrillic */
@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/lora-v20-cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/lora-v20-cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/lora-v20-cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/lora-v20-cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/lora-v20-cyrillic-700italic.svg#Lora') format('svg'); /* Legacy iOS */
}

* {
  font-family: 'Lora', 'Arial', serif;
  font-variant: normal;
  font-feature-settings: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.excalidraw .layer-ui__wrapper .layer-ui__wrapper__footer-right,
.excalidraw .layer-ui__wrapper .App-menu_top .zen-mode-transition:first-child,
.excalidraw .App-toolbar-container .ToolIcon__library,
.excalidraw .ToolIcon_type_floating,
.excalidraw .layer-ui__wrapper .App-menu_top .layer-ui__wrapper__top-right,
.excalidraw .layer-ui__wrapper .App-menu_top .App-menu_top__left .zen-mode-transition .Island,
.excalidraw .App-toolbar-container .mobile-misc-tools-container
{
  display: none !important;
}

.excalidraw {
  --color-primary: #6786B8 !important;
  --color-primary-darker: #3A547F !important;
  --color-primary-darkest: #3A547F !important;
  --color-primary-light: #6786B8 !important;
  --color-primary-chubb: #3A547F !important;
}


@media only screen and (max-width: 450px) {
  .excalidraw .App-top-bar {
    align-items: flex-start !important;
  }

  .excalidraw .App-top-bar .Stack_horizontal {
    grid-auto-flow: row;
  }
}
