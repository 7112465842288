.modal {
    :global {
        .ant-modal-footer {
            display: flex;
            align-items: center;
            justify-content: end;
            height: 61px;
        }
    }
}

.modalBody {
    height: 410px;
}

.notAssigned {
    color: red;
}

.haveOffsetingValue {
    color: #afafaf;
}

.headersDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}

.headerDiv {
    margin-left: auto;
    margin-right: auto;
    width: available;
    display: flex;
    align-items: center;
}

.spaceBetweenCell {
    width: 116px;
}

.warningMessage {
    font-size: 13px;
    color: #ff4d4f;
    margin-right: 10px;
    text-align: left;
}