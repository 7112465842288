.modalBody {
  width: 100%;
  height: 100%;
}

.studentContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
}

.studentAvatar {
  margin-right: 10px;
}

.studentContact {
  color: #403B3B;
}

.tableContainer {
  width: 100%;
  height: 400px;
  background: #FFFEFE;
  border-radius: 5px;
  display: flex;
}

.table {
  height: 100%;
  :global {
    .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
      padding: 5px;
    }
    .ant-table-body {
      overflow-y: auto !important;
    }
    .ant-table-tbody .ant-table-cell-row-hover {
      background-color: #e6f7ff;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background-color: #e6f7ff;
    }
  }
}

.emptyTable {
  :global {
    .ant-table-tbody {
      height: 365px;
    }
  }
}

.addButton {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 5px;
  height: 32px;
  background-color: #6786b8 !important;
  border: 1px solid #6786b8 !important;
  color: #FFFFFF !important;
  font-size: 15px;

  &:hover,
  &:active,
  &:focus {
    background-color: #3a547f !important;
    border-color: #3a547f !important;
  }
}