.marksTable {
  display: block;
  width: 100vw;

  .disabledRow {
    color: #afafaf;
    pointer-events: none;
    background-color: #f5f5f5;
  }

  .inOffsettingCell {
    letter-spacing: 5px;
    font-size: medium;
    color: #3A547F;
    text-align: center;
  }

  .markInputCell, .omissionsInputCell, .labMarkInputCell {
    height: 50px;
    text-align: center;
    font-size: 22px;
    border-radius: 0;
  }

  .labMarkInputCell {
    width: 52px;
    padding: 0;
  }

  .labNumberContainer {
    width: 50px;
    border: 1px solid #d9d9d9;
    background-color: #D4ECFF;
    padding: 6px 0;
  }

  .selectLabNumber {
    :global {
      .ant-select-selector {
        background-color: inherit;
        width: 50px;
        font-size: 15px;
        padding: 0 8px;
        height: 36px;
        align-items: center;
        border: none;
        box-shadow: none !important;
      }
    }
  }

  :global {
    .ant-table-tbody, .ant-table-thead > tr > th {
      padding: 8px 12px;
      white-space: nowrap;
    }
  }

  .selectLabNumberOption {
    text-align: center;
    :global {
      .ant-select-item {
        padding: 0 6px !important;
      }
    }
  }
}

.kebabButton {
  padding-top: 3px !important;
}

.kebabSubButton {
  display: flex;
  align-items: center;
  padding: 0;
}

.fioCell {
  text-align: left;
  max-width: 154px;
}

.markHeaderCell {
  display: flex;
  align-items: center;
}

.hasNoteSign {
  position: absolute;
  border-top: 10px solid #6185be;
  border-right: 11px solid transparent;
  left: 0;
  top: 0;
}

.hiddenOmissionContainer, .hiddenRespectfulOmissionContainer{
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  width: max-content;
  height: 10px;
  font-size: 12px;
  right: 8px;
}

.hiddenOmissionContainer {
  color: red;
}

.hiddenRespectfulOmissionContainer {
  color: green;
}