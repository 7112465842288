.container {

  :global {
    .ant-btn-primary {
      display: inline-flex;
      align-items: center;
      padding: 4px 30px;
      border-radius: 5px;
      height: 40px;
      background-color: #1890ff;
      border: 1px solid #1890ff;
      color: #FFFFFF;

      &:hover,
      &:active,
      &:focus {
        background-color: #3758b6;
        border-color: #3758b6;
      }
    }

    .ant-btn-default {
      display: inline-flex;
      align-items: center;
      border-radius: 5px;
      height: 40px;

      &:hover,
      &:active,
      &:focus {
        border-color: #3758b6;
      }
    }

    .ant-modal-title {
      font-size: 18px;
    }
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .firstRowControls {
      display: flex;
      justify-content: space-between;
      column-gap: 34px;
    }

    .inputContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .inputContainer .markInput, .percentageInput {
      height: 46px;
      border-radius: 8px;
      font-size: 22px;
      margin-bottom: 12px;
    }

    .percentageInput {
      width: 102px;
      text-align: center;
    }

    .markInput{
      max-width: 102px;
    }

    .inputContainer .noteInput {
      font-size: 18px;
      resize: none;
      border-radius: 8px;
    }

    .inputContainer .inputLabel {
      font-size: 18px;
    }
  }

  .selectLabNumber {
    width: 100%;

    :global {
      .ant-select-selector {
        height: 46px;
        border-radius: 8px;
      }

      .ant-select-selection-item {
        font-size: 20px;
        padding-top: 8px;
      }
    }
  }
}