.textInputControls {
    margin-top: 10px;
    justify-content: space-between;
    display: flex;
}

.clearInputButton {
    border-style: none;
    border-radius: 10px;
}

.deleteButton {
    position: absolute;
    bottom: 20px;
}

.warningText, .deletionMessage {
    color: red;
}

.deletionMessage {
    margin-top: 20px;
}

.inputHeader {
    margin-bottom: 5px;
}

.inputWithBottomMargin {
    margin-bottom: 20px;
}