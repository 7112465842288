.labMessage {
  width: fit-content;
  border-radius: 5px;
  margin: 50px auto 0 auto;
  :global {
    .ant-alert-message {
      font-size: 25px;
      margin-bottom: 15px;
    }
    .ant-alert-description {
      font-size: 18px;
    }
  }
}

.subgroupMessage {
  border-radius: 5px;
  margin: 20px 10px 0 0;
  :global {
    .ant-alert-message {
      font-size: 14px;
    }
  }
}

.labInputContainer {
  display: inline-block;
}

.labInput {
  width: 50px;
  border-radius: 5px;
  margin-right: 5px;
}

.labButton {
  border-radius: 5px;
}

@media screen and (min-width: 500px) {
  .labMessage {
    :global {
      .ant-alert-description {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .labInputContainer {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  .labInput {
    width: 78px;
  }
  .labInput input{
    height: 45px;
    font-size: 18px;
    text-align: center;
  }
  .labButton {
    height: 47px;
    font-size: 18px;
  }
}