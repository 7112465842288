.modalContainer {

    :global {
        .ant-modal-content {
            margin-top: 100px;
        }

        .ant-picker {
            border-radius: 8px;
            padding: 15px 18px;
            width: 100%;
        }

        .ant-btn-primary {
            display: inline-flex;
            align-items: center;
            padding: 4px 30px;
            border-radius: 5px;
            height: 40px;
            background-color: #1890ff;
            border: 1px solid #1890ff;
            color: #FFFFFF;
    
            &:hover,
            &:active,
            &:focus {
                background-color: #3758b6;
                border-color: #3758b6;
            }
        }

        .ant-btn-default {
            display: inline-flex;
            align-items: center;
            border-radius: 5px;
            height: 40px;
    
            &:hover,
            &:active,
            &:focus {
                border-color: #3758b6;
            }
        }

        .ant-modal-title {
            font-size: 18px;
        }
    }

    .datePicker {
        padding: 15px 18px;
        resize: none;
        border-radius: 8px;
        margin-bottom: 16px;

        :global {
            .ant-picker-input>input {
                font-size: 18px;
            }

            .ant-picker-suffix {
                width: 20px;
                height: 20px;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .timePickerContainer {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .timePicker {
            padding: 15px 18px;
            font-size: 18px;
            resize: none;
            border-radius: 8px;
            margin-bottom: 16px;
        }
    }

    .descriptionInput {
        padding: 15px 18px;
        font-size: 18px;
        resize: none;
        border-radius: 8px;
        margin-bottom: 16px;
    }

    .auditorySelector {
        width: 100%;
        font-size: 18px;
        margin-bottom: 16px;
        height: 61px;

        :global {
            .ant-picker-input > input{
                font-size: 18px;
            }
    
            .ant-select-selector {
                border-radius: 8px !important;
                padding: 9px 19px !important;
            }
    
            .ant-select-selection-search {
                padding: 8px 8px !important;
            }

            .ant-select-clear {
                width: 20px;
                height: 20px;
                margin-top: -8px;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .groupSelector {
        width: 100%;
        font-size: 18px;
        margin-bottom: 16px;

        :global {
            .ant-select-selector {
                border-radius: 8px !important;
                padding: 11.5px 12px !important;
            }

            .ant-select-selection-placeholder {
                padding: 0 9px;
            }

            .ant-select-selection-search-input {
                padding: 0 10px;
            }

            .ant-select-selection-item {
                border-radius: 5px;
            }

            .ant-select-clear {
                width: 20px;
                height: 20px;
                margin-top: -8px;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .footer {
        display: flex;
        justify-content: end;
    }

    .confirmFooter {
        display: flex;
        justify-content: space-between;
    }

    .deleteButton {
        margin-right: auto;
    }
}

.errorCheckbox {
    :global {
        .ant-checkbox {
            border-color: red !important;
            border: solid 1px;
            border-radius: 3px;
        }
    }
}