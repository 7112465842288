.container {
  padding-top: 10px;
  margin-left: -10px;
  margin-right: -10px;
}

.date {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  line-height: 20px;
  padding-right: 40px;
  color: rgba(0,0,0,.85);
}

.line {
  height: 15px;
  background-color: #d9d9d9;
  width: 1px;
  margin-left: -1px;
}

.weekDays,
.dateGrid tr{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 0 0;
}

.dateGrid td:last-child {
  border-right: 0;
}

.dateGrid td {
  border-left: 0;
  width: 100%;
  min-width: 35px;
}

.cell {
  border: 1px solid;
  border-color: #d9d9d9;
}

.weekDays {
  margin-top: 0.5em;
  font-size: 20px;
}

.weekDays > * {
  font-weight: 400;
  font-size: 15px;
  color: var(--blue-grey-400);
  letter-spacing: 0.1em;
  font-variant: small-caps;
  text-align: center;
}

.currentDay {
  background-color: #1890ff;
  border-radius: 50%;
  height: 0;
  width: 21px;
  padding-top: 3px;
  padding-bottom: 17px;
  text-align: center;
  color: white;
  display: inline-block;
}

.selectedDay {
  background-color: transparent;
  border: 1px solid #1890ff;
  border-radius: 50%;
  height: 0;
  width: 21px;
  padding-top: 3px;
  padding-bottom: 17px;
  text-align: center;
  color: black;
  display: inline-block;
}

.dateGrid button {
  border: none;
  font-size: 13px;
  width: 100%;
  height: 10ch;
  background-color: transparent;
  display: inline-block;
  padding: 0 0;
}

.buttonContent {
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.cellOfGrid {
  color: #000000 !important;
}

.selectedWeekDay {
  color:  #1890ff;
}

.markAsLecture,
.markAsSeminar,
.markAsLabWork,
.markAsConsultation,
.markAsExam,
.markAsOffset,
.markAsCourseProject {
  font-size: 9px;
  border-radius: 5px;
  margin-bottom: 1px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding: 2px;
}

.markAsLecture {
    background-color: #33db24;
}

.markAsSeminar {
    background-color: #8e4fec;
}

.markAsLabWork {
    background-color: #F98F43;
}

.markAsConsultation {
    background-color: #4FE8FF;
}

.markAsExam {
    background-color: #F95B5E;
}

.markAsOffset {
    background-color: #ffd900;
}

.markAsCourseProject {
  background-color: #3039d4;
}

@property --x {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}

.backButton {
  margin-left: 10px;
  color: #3a547f;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.wave {
  animation: click-wave 0.3s;
  position: relative;
  background: radial-gradient(rgba(177, 169, 169, 0.7) var(--x), transparent var(--x));
  background-position: center;
}

@keyframes click-wave {
  100% {
    --x: 100%;
  }
}