.container {
  width: 100%;
  position: relative;
}

.filtersContainer {
  box-sizing: border-box;
  margin-bottom: 10px;
}

.filterSearchContainer {
  margin-bottom: 10px;
}

.filterSearchName {
  font-size: 14px;
  line-height: 14px;
  color: #636363;
}

.searchButton {
  height: 38px;
  width: 100%;
  :global {
    .ant-input-affix-wrapper {
      height: 40px;
    }
  }
}

.noSearchContainer {
  max-width: 300px;
  margin: 200px auto auto;
  text-align: center;
}

.usersContainer {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
}

.tableContainer {
  max-height: calc(75vh);
  overflow: auto;
  width: 100%;
  background: #FFFEFE;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.table {
  width: 100%;
  :global {
    .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
      padding: 5px;
    }
  }
}

.paginator {
  text-align: center;
  margin-top: 10px;
}

.customMobileButton {
  height: 20px;
  flex: 1;
  text-align: end;
  padding: 0;
}

@media only screen and (min-width: 501px) {
  .container {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .filterSearchContainer {
    width: 200px;
    display: inline-block;
  }
  .filterSearchContainer:not(:last-child) {
    margin-right: 70px;
  }
  .filterSearchName {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 20px 10px;
  }
  .filterSearchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .filterSearchName {
    width: 70px;
  }
  .searchButton {
    width: 200px;
  }
}