.subjectDetails {
    overflow: hidden;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px; 
}

.lesson {
    width: 95%; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-around;
    padding: 8px 0; 
}

.lessonDetails {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    padding: 5px 0 4px 8px;
}

.additionalLessonDetails {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    padding: 0 0 6px 8px;
}

.subjectNameAndTime {
    display: flex;
    flex-direction: column;
}

.subjectName {
    font-weight: 400;
    font-size: 20px;
    color: rgba(0,0,0,.85);
}

.lessonTime {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0,0,0,.85);
    padding-top: 5px;
}

.teachers {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0,0,0,.85);
}

.auditory {
    font-size: 16px;
}

.studentGroup{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0,0,0,.85);
}

.subGroupContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.subGroup {
    height: 16px;
    font-size:14px;
    color: grey;
}

.arrowAndDividerContainer {
    display: flex;
    align-items: center;
}

.verticalDivider {
    width: 1px;
    height: 80%;
    background-color: #c2c2c2;
    margin-left: 5px;
}

.arrowIcon {
    color: #1677ff;
}

.markAsLecture,
.markAsSeminar,
.markAsLabWork,
.markAsConsultation,
.markAsExam,
.markAsOffset,
.markAsCourseProject {
    width: 10px;
    float: left;
    border-radius: 10px 0 0 10px;
}

.markAsLecture {
    background-color: #33db24;
}

.markAsSeminar {
    background-color: #8e4fec;
}

.markAsLabWork {
    background-color: #F98F43;
}

.markAsConsultation {
    background-color: #4FE8FF;
}

.markAsExam {
    background-color: #F95B5E;
}

.markAsOffset {
    background-color: #ffd900;
}

.markAsCourseProject {
    background-color: #3039d4;
}

@media screen and (360px <= width < 370px) {
    .subjectName {
        font-size: 19px;
    }
     .teacher {
        font-size: 16px;
    }
}

@media screen and (320px <= width < 360px) {
    .subjectName {
        font-size: 17px;
    }

    .auditory {
        font-size: 13px;
    }

    .lessonTime, .studentGroup, .teacher {
        font-size: 16px;
    }
    .subjectNameAndTime { 
        width: fit-content;
    }
}
