.container {
    margin: auto;
    max-width: 700px;
    padding: 15px 30px;
    border-radius: 5px;
    margin-top: 30px;
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 7px #f0f0f0;

    .headerIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85px;
        height: 85px;
        margin: auto;
        position: relative;
        top: -50px;
        border-radius: 80px;
        color: #FFFFFF;
        border: 6px solid #FFFFFF;
    }

    .header {
        margin: auto;
        text-align: center;
        font-size: 1.05rem;
        font-weight: bold;
        position: relative;
        top: -45px;
        color: #2c2e30;
    }

    .content {
        margin-bottom: 50px;
    }

    .footer {
        position: absolute;
        left: 0;
        bottom: 15px;
        width: 100%;
        text-align: center;
        color: #686a6e;
    }
}

@media screen and (max-width: 600px) {
    .container {
        padding: 15px 20px;
    }
}

@media screen and (max-width: 375px) {
    .container {
        padding: 15px;
    }
}