.container {
  width: 100%;
  position: relative;
  margin-top: -10px;
  margin-bottom: -10px;
}

.noSearchContainer {
  max-width: 300px;
  margin: 200px auto auto;
  text-align: center;
  opacity: 0.7;
}

.tabs {
  align-self: center;
  justify-self: start;

  label {
    width: 150px;
    text-align: center;
    overflow: hidden;
  }

  :global {
    .ant-segmented {
      border-radius: 5px;
    }

    .ant-segmented-group label:first-child {
      border-radius: 5px 0 0 5px;
    }

    .ant-segmented-group label:last-child {
      border-radius: 0 5px 5px 0;
    }

    .ant-segmented-item {
      border: 1px solid transparent;
    }

    .ant-segmented-item-selected,
    .ant-segmented-thumb,
    .ant-segmented-thumb-motion-appear,
    .ant-segmented-thumb-motion-appear-active .ant-segmented-thumb-motion {
      color: #0c63e4 !important;
      background-color: #e7f1ff;
      border: 1px solid #7ba9ed;
    }

    .ant-segmented-group {
      background-color: #ebebeb;
    }
  }
}

.tableControlsDiv {
  width: 100%;
  display: grid;
  grid-template-columns: 300px 150px 1fr 160px auto;
  column-gap: 20px;
  row-gap: 5px;
  margin: 15px 0 13px 0;
  padding-right: 3px;
}

@media (max-width: 1150px) {
  .tableControlsDiv {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto;
    grid-column-gap: 5px;
    grid-row-gap: 15px;
  }

  .tableControlsDiv > :nth-child(1) {
    grid-row: 1;
    grid-column: span 2;
  }

  .tableControlsDiv > :nth-child(2) {
    grid-row: 2;
    grid-column: 1;
  }

  .tableControlsDiv > :nth-child(3) {
    grid-row: 2;
    grid-column: span 2;
  }

  .tableControlsDiv > :nth-child(4) {
    grid-row: 2;
    grid-column: 4;
  }

  .tableControlsDiv > :nth-child(5) {
    grid-column: span 2;
    justify-self: end;
  }
}


@media (max-width: 683px) {
  .tableControlsDiv {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-row-gap: 15px;
    grid-column-gap: 5px;
    grid-auto-flow: column dense;
  }

  .tableControlsDiv > :nth-child(3) {
    justify-self: center;
    grid-row: 3;
    grid-column: span 4;
  }

  .tableControlsDiv > :nth-child(5) {
    justify-self: end;
    grid-row: 1;
    grid-column: span 2;
  }
}

@media (max-width: 635px) {
  .tableControlsDiv {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    grid-row-gap: 15px;
    grid-auto-flow: column dense;
    grid-column-gap:0;
  }

  .tableControlsDiv > :nth-child(1) {
    justify-self: start;
    grid-row: 1;
    grid-column: span 7;
  }

  .tableControlsDiv > :nth-child(3) {
    justify-self: center;
    grid-row: 3;
    grid-column: span 11;
  }
  .tableControlsDiv > :nth-child(4) {
    justify-self: end;
    grid-row: 2;
    grid-column: 11;
  }
  .tableControlsDiv > :nth-child(5) {
    justify-self: end;
    grid-row: 1;
    grid-column: span 4;
  }
}


.rightTableControl {
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  height: max-content;
  justify-self: end;
}

.labsControl {
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  height: max-content;
  justify-self: end;
}

.gradeSettingInput {
  height: 35px;
  margin: auto 0;
  justify-self: start;

  :global {
    .ant-input-number {
      width: 55px;
      text-align: center;
      border-radius: 5px 0 0 5px;
    }

    .ant-btn-primary {
      height: 100%;
      border-radius: 0 5px 5px 0;
      transition-duration: 1s;
    }
  }
}

.omissionGradeSettingInput {
  :global {
    .ant-input-number-input {
      color: red !important;
    }
  }
}