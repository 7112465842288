.container {
    transition: all .5s ease-in-out;
    animation: smooth-appear .5s ease;
}

.errorMessageContainer {
    font-size: 16px;
    text-align: center;
}

.errorMessageContainer a {
    font-size: 20px;
}

@keyframes smooth-appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}