.container {
  width: 100%;
  position: relative;
  margin-top: -10px;
  margin-bottom: -10px;
}

.filtersContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.addContainer {
  align-self: end;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 10px;
}

.addButton {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 5px;
  height: 38px;
  background-color: #6786b8 !important;
  border: 1px solid #6786b8 !important;
  color: #FFFFFF !important;
  font-size: 15px;

  &:hover,
  &:active,
  &:focus {
    background-color: #3a547f !important;
    border-color: #3a547f !important;
  }
}

.filterContainer {
  margin-bottom: 10px;
}

.filterContainer:not(:last-child) {
  margin-right: 70px;
}

.filterName {
  font-size: 14px;
  line-height: 14px;
  color: #636363;
  margin-bottom: 10px;
}

.filterButton {
  width: 200px;
  height: 38px;
  border-radius: 5px;
}

.noSearchContainer {
  max-width: 300px;
  margin: 200px auto auto;
  text-align: center;
}

.announcementsContainer {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
}

.tableContainer {
  max-height: calc(100vh - 240px);
  overflow: auto;
  width: 100%;
  background: #FFFEFE;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.table {
  width: 100%;
  :global {
    .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
      padding: 5px;
    }
    .ant-table-tbody > tr:hover > td {
      background-color: #e6f7ff;
    }
  }
}

.pastAnnouncement {
  color: #b0b5b9;
}

.groupsColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.group {
  margin: 4px;
  padding: 7px 12px;
  height: 35px;
  width: 80px;
  border-radius: 15px;
  background-color: #D4ECFF;
}

.paginator {
  text-align: center;
  margin-top: 10px;
}

.auditoryColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.actionsColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

@media screen and (min-width: 1400px) {
  .repeatIcon {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20%;
    margin: auto 10px auto 0;
  }
}

@media screen and (max-width: 1399px) {
  .dateColumn {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100px;
  }
}