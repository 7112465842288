.container {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 15px;
  word-break: break-word;
  transition: all .25s ease-in-out;
  animation: slide-up 0.2s ease;
}

.container:hover {
  transform: scale(1.05);
}

.container:last-child {
  margin-bottom: 0;
}

.container > div {
  margin-bottom: 10px;
}

.title, .optionedTitle {
  font-size: 15px;
  font-weight: bold;
  color: #3a547f;
  float: left;
  height: 15px;
}

.optionedTitle {
  justify-content: space-between;
  display: flex;
}

.optionsDiv {
  justify-content: space-between;
  display: flex;
  width: fit-content;
}

.iconButton {
  background-color: transparent;
  border-style: none;
  margin-left: 10px;
}

.iconButton svg {
  color: #3A547F;
}

.iconButton:disabled svg {
  color: gray;
}

.displayInline {
  white-space: break-spaces;
  word-break: keep-all;
}

.data {
  font-size: 14px;
}

.inlineData {
  align-self: end;
  flex: 1;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}