.bugReportModal {
  :global {
    .ant-modal-body {
      padding-top: 18px;
    }
  }
}

.bugDescription {
  margin-bottom: 12px;
  min-width: fit-content;
}

.fio,
.email,
.attachments {
  margin-bottom: 12px;
}

.buttonWithMessage {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  button {
    margin-right: 10px;
  }
}

.imageContainer {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 15px;
}

.selectedFiles {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.selectedFiles span {
  margin-right: 15px
}