@import '../../../../styles/animations.scss';

.container {
    animation: smooth-appear .5s ease;

    &--unmounted {
        animation: slide-down-disappear .5s ease-in-out;
    }
}

.extra {
    width: 100%;
    text-align: center;
    color: #686a6e;
}

.errorContainer {
    margin-bottom: 15px;
    animation: smooth-appear .5s ease;
}