.container {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 16px;
    max-width: 100vw;

    .studentWithNoSubGroup{
        color: #ff0000; 
    }

    .subgroupStudents {
        overflow: hidden;
        display: block;
        width: 100vw;
    
        :global {
            .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
                white-space: nowrap;
                padding: 10px;
            }
    
            .ant-checkbox-inner, .ant-checkbox-input {
                transform: scale(1.5);
            }
        }
    }

    :global {
        .ant-switch {
            background-color: #94ca7f;
        }

        .ant-switch-checked {
            background-color: #7ec1ff;
        }
    }

    .menuButtons {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .save {
            float: right;
            margin-right: 10%;
        }
    }

    @property --x {
        syntax: '<percentage>';
        inherits: false;
        initial-value: 0%;
    }

    .backButton {
        margin-top: 2px;
        align-self: center;
        color: #3a547f;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
    }
      
    .wave {
        animation: click-wave 0.3s;
        position: relative;
        background: radial-gradient(rgba(177, 169, 169, 0.7) var(--x), transparent var(--x));
        background-position: center;
    }
      
    @keyframes click-wave {
        100% {
          --x: 100%;
        }
    }
}