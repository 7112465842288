.underline {
    position: relative;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    color: #3A547F;

    &:hover,
    &:focus {
        color: #3A547F;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 2px;
        background-color: #3A547F;
        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    }
}

@media (hover: hover) and (pointer: fine) {
    .underline:hover::before {
        left: 0;
        right: auto;
        width: 100%;
    }
}